import React from 'react';

// styles
import './Label.css';

function Label({ children, classes, mods }) {
	let classNames = classes ? ` ${classes.join(' ')}` : '';
	let modNames = mods && mods.length ? ` ${mods.map((mod) => `label--${mod}`).join(' ')}` : '';

	return <label className={`label${classNames}${modNames}`}>{children}</label>;
}

export default Label;
