import React, { useEffect, useRef, useState } from 'react';

// router
import { Redirect, withRouter } from 'react-router-dom';

// forms
import Input from 'components/forms/Input/Input';
import Label from 'components/forms/Label/Label';

// core
import Dialog from 'components/core/Dialog/Dialog';
import Section from 'components/core/Section/Section';
import Tag from 'components/core/Tag/Tag';
import Typography from 'components/core/Typography/Typography';
import Wrap from 'components/core/Wrap/Wrap';

// requests
import { getQuestion } from 'requests/questions';

import { filterJservice, levenshteinDistance } from 'utils';

// styles
import './Clue.css';

function Clue({ match, setPoints, setQuestionsAsked }) {
	// refs
	const refProgress = useRef();
	const refSeconds = useRef();

	// vars
	const totalSeconds = 15;

	// state
	const [activeClue, setActiveClue] = useState(null);
	const [answer, setAnswer] = useState('');
	const [hasAnswered, setHasAnswered] = useState(false);
	const [hasStarted, setHasStarted] = useState(false);
	const [timeToAnswerInterval, setTimeToAnswerInterval] = useState(null);
	const [isDialogCorrectOpen, setIsDialogCorrectOpen] = useState(false);
	const [isDialogErrorOpen, setIsDialogErrorOpen] = useState(false);
	const [isFinished, setIsFinished] = useState(false);
	const [isTimeUp, setIsTimeUp] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		const answerSanitized = filterJservice(answer).toLowerCase();
		const answerClueSanitized = activeClue.answer.toLowerCase();
		const isExact = answerSanitized === answerClueSanitized;
		const levDist = levenshteinDistance(answerSanitized, answerClueSanitized);

		// if exact match
		// or levenshtein distance is less than 3
		const isSuccess = isExact || (answerSanitized.length > 3 && levDist <= 3);

		if (isSuccess) {
			// set success dialog
			setIsDialogCorrectOpen(true);

			// add points
			setPoints((prevState) => {
				return prevState + activeClue.value;
			});
		} else {
			// set error dialog
			setIsDialogErrorOpen(true);

			// subtract points
			setPoints((prevState) => {
				return prevState - activeClue.value;
			});
		}

		clearInterval(timeToAnswerInterval);

		// add clue to questions asked
		setQuestionsAsked((prevState) => [
			...prevState,
			{
				success: isSuccess,
				clue: activeClue,
				guess: answer,
			},
		]);

		setHasAnswered(true);
	};

	const handleConfirm = () => {
		clearInterval(timeToAnswerInterval);
		setIsFinished(true);
	};

	// get clue when url params change
	useEffect(() => {
		if (match.params.category && match.params.value) {
			getQuestion({
				value: match.params.value,
				category: match.params.category,
				success: (data) => {
					console.log('QUESTION', data.questions[0]);
					setActiveClue(data.questions[0]);
				},
			});
		}
	}, [match]);

	// set timer and music on load
	useEffect(() => {
		if (activeClue && !hasStarted) {
			// prevent from running more than once
			setHasStarted(true);

			// vars
			const audioMusic = new Audio('/audio/timer.mp3');
			const audioExpired = new Audio('/audio/expired.mp3');

			let currentSecond = totalSeconds;

			const timeToAnswer = setInterval(() => {
				// if current second is less than total seconds
				if (currentSecond <= totalSeconds && currentSecond >= 0) {
					// set progress bar value
					if (refProgress && refProgress.current) {
						refProgress.current.value = totalSeconds - currentSecond;
					}

					// set progress time
					if (refSeconds && refSeconds.current) {
						const time = String(currentSecond).length > 1 ? currentSecond : `0${currentSecond}`;
						refSeconds.current.innerText = time;
					}

					// decrement current second
					currentSecond--;
				} else {
					// clear interval and end time
					clearInterval(timeToAnswer);

					// play expired audio
					const audioExpiredPromise = audioExpired.play();

					if (audioExpiredPromise) {
						audioExpiredPromise.catch((e) => {
							if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
								console.log(e.name);
							}
						});
					}

					/* // HARD MODE
					if (activeClue && !hasAnswered) {
						// subtract points
						setPoints((prevState) => {
							return prevState - activeClue.value;
						});
					}
					*/

					// add clue to questions asked
					setQuestionsAsked((prevState) => [
						...prevState,
						{
							success: false,
							clue: activeClue,
							guess: null,
						},
					]);

					setIsDialogErrorOpen(true);
					setIsTimeUp(true);
				}
			}, 1000);

			setTimeToAnswerInterval(timeToAnswer);

			if (audioMusic) {
				// when music can be played through
				audioMusic.oncanplaythrough = () => {
					// start playing music
					//audioMusic.play();
				};
			}
		}
	}, [activeClue, hasAnswered, hasStarted, setPoints, setQuestionsAsked]);

	if (!activeClue) {
		return false;
	}

	if (isFinished) {
		return <Redirect to="/board" />;
	}

	return (
		<div className="clue">
			<div className="clue__progress">
				<progress
					className="progress__progress"
					max={totalSeconds}
					ref={refProgress}
					value="0"></progress>
				<div className="progress__time">
					0:<span ref={refSeconds}>{totalSeconds}</span>
				</div>
			</div>
			<Section classes={['clue__section']}>
				<Wrap classes={['clue__wrap']}>
					<div className="clue__content">
						<div>
							<Tag>{activeClue.category.name}</Tag>
							<Typography classes={['clue__text']} mods={['question']}>
								{activeClue.question}
							</Typography>
						</div>
					</div>
					<form className="clue__form" onSubmit={(e) => handleSubmit(e)}>
						<ul className="form__list">
							<li>
								<Label for="clue-answer" mods={['screenreader']}>
									What is...
								</Label>
								<Input
									ariaLabel="Enter an answer"
									onChange={(e) => setAnswer(e.target.value)}
									name="clue-answer"
									placeholder="What is..."
									required={true}
									value={answer}
								/>
							</li>
							<li>
								<Input type="submit" value="Answer" />
							</li>
						</ul>
					</form>
				</Wrap>
			</Section>
			<Dialog
				classes={['clue__dialog']}
				content={`The correct answer is "${filterJservice(activeClue.answer)}".`}
				isOpen={isDialogErrorOpen}
				mods={['error']}
				onConfirm={() => handleConfirm()}
				setIsOpen={setIsDialogErrorOpen}
				title={isTimeUp ? `Time Up` : `Wrong`}
				type="alert"
			/>
			<Dialog
				classes={['clue__dialog']}
				content={`That's right! You earned $${activeClue.value}. Keep up the great work.`}
				isOpen={isDialogCorrectOpen}
				mods={['success']}
				onConfirm={() => handleConfirm()}
				setIsOpen={setIsDialogCorrectOpen}
				title="Correct"
				type="alert"
			/>
		</div>
	);
}

export default withRouter(Clue);
