import React from 'react';

// core
import Button from 'components/core/Button/Button';
import Typography from 'components/core/Typography/Typography';
import Wrap from 'components/core/Wrap/Wrap';

// icons
import IconCheck from '@material-ui/icons/Check';
import IconClear from '@material-ui/icons/Clear';

// styles
import './Dialog.css';

const Dialog = ({
	children,
	classes,
	content,
	isOpen,
	setIsOpen,
	mods,
	onConfirm,
	title,
	translation,
	type,
}) => {
	let classNames = classes ? ` ${classes.join(' ')}` : '';
	let modNames = mods && mods.length ? ` ${mods.map((mod) => `dialog--${mod}`).join(' ')}` : '';

	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}

		if (setIsOpen) {
			setIsOpen(false);
		}
	};

	const renderContent = () => {
		if (type === 'alert') {
			let icon = <IconClear />;

			if (mods && mods.includes('success')) {
				icon = <IconCheck />;
			}

			return (
				<div className="dialog__alert">
					<div className="dialog__icon">{icon}</div>
					<Typography classes={['dialog__title', 'h1']} component="h3">
						{title}
					</Typography>
					<Typography mods={['body']}>{content}</Typography>
					<Button mods={['yellow']} onClick={() => handleConfirm()}>
						Ok
					</Button>
				</div>
			);
		} else {
			const renderContent = () => {
				if (children) {
					return children;
				} else if (content) {
					return content;
				}
			};

			const renderBtns = () => {
				if (type === 'confirm') {
					return (
						<ul className="dialog__btns">
							<li>
								<Button onClick={() => setIsOpen(false)} mods={['border']}>
									{translation && translation.cancel ? translation.cancel : 'Cancel'}
								</Button>
							</li>
							<li>
								<Button onClick={() => handleConfirm()}>
									{translation && translation.confirm ? translation.confirm : 'Confirm'}
								</Button>
							</li>
						</ul>
					);
				}
				return false;
			};

			return (
				<>
					<Typography component="h3" classes={['h1']}>
						{title}
					</Typography>
					<div className="dialog__text">{renderContent()}</div>
					{renderBtns()}
				</>
			);
		}
	};

	if (!isOpen) {
		return false;
	}

	if (type) {
		modNames += ` dialog--${type}`;
	}

	return (
		<div className={`dialog${classNames}${modNames}`}>
			<div className="dialog__overlay"></div>
			<Wrap classes={['dialog__wrap']}>
				<div className="dialog__content">
					<div className="dialog__box">{renderContent()}</div>
				</div>
			</Wrap>
		</div>
	);
};

export default Dialog;
