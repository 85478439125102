import React, { useEffect } from 'react';

// router
import { Link } from 'react-router-dom';

// components
import Section from 'components/core/Section/Section';
import Typography from 'components/core/Typography/Typography';
import Wrap from 'components/core/Wrap/Wrap';

// icons
import IconDonutLarge from '@material-ui/icons/DonutLarge';

// styles
import './Board.css';

function Board({ categories, setQuestionsAsked, questionsAsked }) {
	// renderers
	const renderCategories = () => {
		if (!categories.length) {
			return false;
		}

		return categories.map((cat, key) => {
			return (
				<th key={key}>
					<Typography component="span" classes={['h2']} mods={['shadow']}>
						{cat.name}
					</Typography>
				</th>
			);
		});
	};

	const renderValues = (value) => {
		if (!categories.length) {
			return false;
		}

		return categories.map((category, key) => {
			const questionAsked = questionsAsked.find(
				(q) => q.clue.category._id === category._id && q.clue.value === value
			);

			if (questionAsked) {
				return <td className="table__cell table__cell--empty" key={key}></td>;
			}

			return (
				<td key={key}>
					<Link className="table__value" to={`/clue/${category._id}/${value}`}>
						<Typography component="span" mods={['value']}>
							${value}
						</Typography>
					</Link>
				</td>
			);
		});
	};

	const renderBoard = () => {
		if (!categories.length) {
			return (
				<div className="board__loading">
					<IconDonutLarge />
				</div>
			);
		}

		return (
			<div className="board__mask">
				<div className="mask__mask">
					<table className="board__table">
						<thead>
							<tr>{renderCategories()}</tr>
						</thead>
						<tbody>
							<tr>{renderValues(200)}</tr>
							<tr>{renderValues(400)}</tr>
							<tr>{renderValues(600)}</tr>
							<tr>{renderValues(800)}</tr>
							<tr>{renderValues(1000)}</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	};

	useEffect(() => {
		localStorage.setItem('questionsAsked', JSON.stringify(questionsAsked));
	}, [questionsAsked]);

	return (
		<div className="board">
			<Section>
				<Wrap>{renderBoard()}</Wrap>
			</Section>
		</div>
	);
}

export default Board;
