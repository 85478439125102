import React from 'react';

// core
import Typography from 'components/core/Typography/Typography';
import Wrap from 'components/core/Wrap/Wrap';

// icons
import { ReactComponent as IconCanada } from 'icons/canada.svg';

// styles
import './Footer.css';

function Footer() {
	return (
		<footer className="footer">
			<Wrap classes={['footer__wrap']}>
				<Typography classes={['footer__title', 'h3']} mods={['gyparody']}>
					{process.env.REACT_APP_TITLE}
				</Typography>
				<Typography classes={['footer__disclaimer']} component="div">
					This game is not affiliated, associated or endorsed by Jeopardy!, Jeopardy Productions,
					Inc. or Sony Pictures Digital Inc. Special thanks to both{' '}
					<a href="https://j-archive.com" rel="noreferrer noopener" target="_blank">
						j-archive.com
					</a>{' '}
					and{' '}
					<a href="https://jservice.io" rel="noreferrer noopener" target="_blank">
						jservice.io
					</a>
					.
				</Typography>
				<Typography classes={['footer__credit']} component="span">
					Made in <IconCanada />
				</Typography>
			</Wrap>
		</footer>
	);
}

export default Footer;
