// styles
import './Wrap.css';

function Wrap({ children, classes }) {
	const classNames = classes ? ` ${classes.join(' ')}` : '';

	return <div className={`wrap${classNames}`}>{children}</div>;
}

export default Wrap;
