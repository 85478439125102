// styles
import './Tag.css';

function Tag({ children, classes, component, mods }) {
	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames =
		mods && mods.length ? ` ${mods.map((mod) => `typography--${mod}`).join(' ')}` : '';

	return <div className={`tag${classNames}${modNames}`}>{children}</div>;
}

export default Tag;
