import React, { useState, useEffect } from 'react';

// router
import { Route, Switch, withRouter } from 'react-router-dom';

// pages
import Board from 'components/pages/Board/Board';
import Clue from 'components/pages/Clue/Clue';

// core
import Button from 'components/core/Button/Button';
import Dialog from 'components/core/Dialog/Dialog';
import Panel from 'components/core/Panel/Panel';
import Typography from 'components/core/Typography/Typography';

// ui
import Nav from 'components/ui/Nav/Nav';
import Questions from 'components/ui/Questions/Questions';

// requests
import { getGame } from 'requests/games';

// styles
import './Game.css';

function Game({ location, username }) {
	// storage
	const localPoints = localStorage.getItem('points');
	const localQuestionsAsked = localStorage.getItem('questionsAsked');
	const localCategories = localStorage.getItem('categories');

	// state
	const [categories, setCategories] = useState(localCategories ? JSON.parse(localCategories) : []);
	const [isDialogResetOpen, setIsDialogResetOpen] = useState(false);
	const [isPanelQuestionsOpen, setIsPanelQuestionsOpen] = useState(false);
	const [points, setPoints] = useState(localPoints ? Number(localPoints) : 0);
	const [questionsAsked, setQuestionsAsked] = useState(
		localQuestionsAsked && localQuestionsAsked.length ? JSON.parse(localQuestionsAsked) : []
	);

	const totalQuestions = 30;
	const isFinished = questionsAsked.length >= totalQuestions && location.pathname.includes('board');

	const handleReset = () => {
		// reset local storage
		localStorage.setItem('questionsAsked', JSON.stringify([]));
		localStorage.setItem('categories', JSON.stringify([]));

		setCategories([]);
		setQuestionsAsked([]);
		setPoints(0);
	};

	const renderFinishedMessage = () => {
		const verb = points >= 0 ? 'made' : 'lost';
		let msg = `You ${verb} $${Math.abs(points)} dollars. `;

		if (points > 400) {
			msg += 'Great work!';
		} else {
			msg += 'Better luck next time.';
		}

		return msg;
	};

	useEffect(() => {
		if (!categories.length) {
			getGame({
				success: (data) => {
					setCategories(data.categories);
				},
			});
		}
	}, [categories]);

	// set points in local storage
	useEffect(() => {
		localStorage.setItem('points', points);
	}, [points]);

	// set categories in local storage
	useEffect(() => {
		localStorage.setItem('categories', JSON.stringify(categories));
	}, [categories]);

	return (
		<div className="game">
			<Nav
				points={points}
				questionsAsked={questionsAsked}
				setIsDialogResetOpen={setIsDialogResetOpen}
				setIsPanelQuestionsOpen={setIsPanelQuestionsOpen}
				username={username}
			/>
			<Switch>
				<Route path="/board">
					<Board
						categories={categories}
						setQuestionsAsked={setQuestionsAsked}
						questionsAsked={questionsAsked}
					/>
				</Route>
				<Route path="/clue/:category?/:value?">
					<Clue setPoints={setPoints} setQuestionsAsked={setQuestionsAsked} />
				</Route>
			</Switch>
			<Dialog
				content="Are you sure you want to reset the game?"
				isOpen={isDialogResetOpen}
				mods={['center']}
				onConfirm={() => handleReset()}
				setIsOpen={setIsDialogResetOpen}
				title="Reset Game"
				translation={{
					confirm: 'Reset Game',
				}}
				type="confirm"
			/>
			<Dialog classes={['clue__dialog']} isOpen={isFinished} title="Finished" mods={['center']}>
				<Typography>{renderFinishedMessage()}</Typography>
				<Button onClick={() => handleReset()}>Reset Game</Button>
			</Dialog>
			<Panel isOpen={isPanelQuestionsOpen} setIsOpen={setIsPanelQuestionsOpen}>
				<Questions questions={questionsAsked} />
			</Panel>
		</div>
	);
}

export default withRouter(Game);
