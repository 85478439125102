import React from 'react';

// styles
import './Input.css';

function Input({ ariaLabel, id, name, onChange, placeholder, required, type, value }) {
	const inputProps = {
		type: type ? type : 'text',
	};

	if (ariaLabel) {
		inputProps['aria-label'] = ariaLabel;
	}

	if (id) {
		inputProps.id = id;
	} else if (name) {
		inputProps.id = name;
	}

	if (name) {
		inputProps.name = name;
	}

	if (onChange) {
		inputProps.onChange = onChange;
	}

	if (placeholder) {
		inputProps.placeholder = placeholder;
	}

	if (required) {
		inputProps.required = 'required';
	}

	if (value) {
		inputProps.value = value;
	}

	return (
		<div className="input">
			<input {...inputProps} />
		</div>
	);
}

export default Input;
