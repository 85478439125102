export const getGame = ({ error, success }) => {
	const baseUrl = `${process.env.REACT_APP_API_URL}/games`;

	let url = baseUrl;
	let params = {};

	params = new URLSearchParams(params);

	if (params) {
		url += `?${params}`;
	}

	return fetch(url, {
		method: 'get',
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				if (error) {
					error(response);
				}
				throw new Error('Something went wrong');
			}
		})
		.then((json) => {
			if (success) {
				success(json);
			}

			return json;
		})
		.catch((error) => console.log(error));
};
