// styles
import './Overlay.css';

function Overlay({ classes, isActive, onClick }) {
	let classNames = classes ? ` ${classes.join(' ')}` : '';
	let overlayProps = {};

	if (isActive) {
		classNames += ' overlay--active';
	}

	if (onClick) {
		overlayProps.onClick = onClick;
	}

	return <div className={`overlay${classNames}`} {...overlayProps}></div>;
}

export default Overlay;
