import { useRef } from 'react';

// core
import Button from 'components/core/Button/Button';
import Overlay from 'components/core/Overlay/Overlay';

// icons
import IconClear from '@material-ui/icons/Clear';

// styles
import './Panel.css';

function Panel({ children, classes, isOpen, mods, setIsOpen }) {
	// refs
	const refPanel = useRef();

	// vars
	let classNames = classes ? ` ${classes.join(' ')}` : '';
	let modNames = mods && mods.length ? ` ${mods.map((mod) => `panel--${mod}`).join(' ')}` : '';

	if (isOpen) {
		modNames += ' panel--active';
	}

	return (
		<>
			<Overlay isActive={isOpen} onClick={() => setIsOpen(false)} />
			<aside className={`panel${classNames}${modNames}`} ref={refPanel}>
				{children}
				<Button classes={['panel__close']} mods={['icon', 'grey']} onClick={() => setIsOpen(false)}>
					<IconClear />
				</Button>
			</aside>
		</>
	);
}

export default Panel;
