// styles
import './Section.css';

function Section({ children, classes, component }) {
	const Tag = component ? component : 'section';
	const classNames = classes ? ` ${classes.join(' ')}` : '';

	return <Tag className={`section${classNames}`}>{children}</Tag>;
}

export default Section;
