// router
import { Link } from 'react-router-dom';

// core
import Typography from 'components/core/Typography/Typography';
import Wrap from 'components/core/Wrap/Wrap';

// icons
import IconApps from '@material-ui/icons/Apps';
import IconExitToApp from '@material-ui/icons/ExitToApp';
import IconLocalAtm from '@material-ui/icons/LocalAtm';
import IconAccountCircle from '@material-ui/icons/AccountCircle';

// styles
import './Nav.css';

function Nav({
	classes,
	points,
	questionsAsked,
	setIsDialogResetOpen,
	setIsPanelQuestionsOpen,
	username,
}) {
	const classNames = classes ? ` ${classes.join(' ')}` : '';

	return (
		<nav className={`nav${classNames}`}>
			<Wrap classes={['nav__wrap']}>
				<div className="nav__container">
					<div className="nav__logo">
						<Typography component="h2" classes={['logo__text']} mods={['gyparody']}>
							<Link to="/">
								<Typography>Jeopardy!</Typography>
							</Link>
						</Typography>
					</div>
					<ul className="nav__list">
						<li
							className="list__item list__item--questions"
							onClick={() => setIsPanelQuestionsOpen(true)}>
							<i className="item__icon">
								<IconApps />
							</i>
							{questionsAsked.length} <span className="scrnlg">Questions</span>
						</li>
						<li className="list__item">
							<i className="item__icon">
								<IconLocalAtm />
							</i>
							{points} <span className="scrnlg">Points</span>
						</li>
						<li className="list__item list__item--desktop">
							<i className="item__icon">
								<IconAccountCircle />
							</i>
							{username}
						</li>
						<li className="list__item list__item--reset">
							<Link to="/board" onClick={() => setIsDialogResetOpen(true)}>
								<i className="item__icon">
									<IconExitToApp />
								</i>
								<span className="scrnlg">Reset Game</span>
							</Link>
						</li>
					</ul>
				</div>
			</Wrap>
		</nav>
	);
}

export default Nav;
