import React, { useEffect, useState } from 'react';

// router
import { BrowserRouter, Route } from 'react-router-dom';

// components
import Game from 'components/pages/Game/Game';
import Home from 'components/pages/Home/Home';

// styles
import 'App.css';

function App() {
	// storage
	const localUsername = localStorage.getItem('username');

	// state
	const [username, setUsername] = useState(localUsername ? localUsername : '');

	// set username in local storage
	useEffect(() => {
		localStorage.setItem('username', username);
	}, [username]);

	return (
		<div className="app">
			<BrowserRouter>
				<Route path={['/board', '/clue']}>
					<Game username={username} />
				</Route>
				<Route exact path="/">
					<Home setUsername={setUsername} username={username} />
				</Route>
			</BrowserRouter>
		</div>
	);
}

export default App;
