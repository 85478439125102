import React from 'react';

// router
import { useHistory } from 'react-router-dom';

// core
import Button from 'components/core/Button/Button';
import Section from 'components/core/Section/Section';
import Typography from 'components/core/Typography/Typography';
import Wrap from 'components/core/Wrap/Wrap';

// forms
import Input from 'components/forms/Input/Input';

// ui
import Footer from 'components/ui/Footer/Footer';

// styles
import './Home.css';

function Home({ setUsername, username }) {
	const history = useHistory();

	const handleSubmit = () => {
		history.push('/board');
	};

	return (
		<>
			<Section classes={['home']}>
				<img alt="Jeopardy! Live Set" className="home__bg" src="/img/bg.jpg" />
				<video className="home__video" autoPlay loop muted>
					<source src="/video/clouds.mp4" type="video/mp4" />
				</video>
				<Wrap classes={['home__wrap']}>
					<div className="home__box">
						<Typography classes={['home__title']} component="h1" mods={['gyparody']}>
							<Typography component="span" mods={['reader']}>
								Jeopardy!
							</Typography>
							<img alt="Jeopardy! Logo" className="home__logo" src="/img/logo.png" />
						</Typography>
						<div className="box__card">
							<Typography>
								The <strong>free</strong>, <em>unofficial</em> single-player Jeopardy! game. Enter a
								username to get started.
							</Typography>
							<form className="home__form" onSubmit={() => handleSubmit()}>
								<ul className="form__list">
									<li className="list__item">
										<Input
											ariaLabel="Username"
											onChange={(e) => setUsername(e.target.value)}
											placeholder="Enter a username..."
											required
											type="text"
											value={username}
										/>
									</li>
									<li className="list__item">
										<Button mods={['yellow']} type="submit">
											Play Now
										</Button>
									</li>
								</ul>
							</form>
						</div>
						<Typography classes={['home__footer']}>
							In memory of Alex Trebek. Created for the biggest Jeopardy fan I know, Brian Lynch.
						</Typography>
					</div>
				</Wrap>
			</Section>
			<Footer />
		</>
	);
}

export default Home;
