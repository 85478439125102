export const getQuestion = ({ error, category, success, value }) => {
	const baseUrl = `${process.env.REACT_APP_API_URL}/questions`;

	let url = baseUrl;
	let params = {};

	if (category) {
		params.category = category;
	}

	if (value) {
		params.value = value;
	}

	params = new URLSearchParams(params);

	if (params) {
		url += `?${params}`;
	}

	return fetch(url, {
		method: 'get',
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				if (error) {
					error(response);
				}
				throw new Error('Something went wrong');
			}
		})
		.then((json) => {
			if (success) {
				success(json);
			}

			return json;
		})
		.catch((error) => console.log(error));
};
