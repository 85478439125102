// core
import Typography from 'components/core/Typography/Typography';

// icons
import IconCheck from '@material-ui/icons/Check';
import IconClear from '@material-ui/icons/Clear';

// styles
import './Questions.css';

function Questions({ classes, questions }) {
	const classNames = classes ? ` ${classes.join(' ')}` : '';

	const renderQuestions = () => {
		if (!questions.length) {
			return (
				<Typography classes={['questions__empty']}>
					You haven't been given any clues yet. Choose a question on the board to get started.
				</Typography>
			);
		}

		const listItems = questions.map((q, key) => {
			const icon = q.success ? <IconCheck /> : <IconClear />;
			const status = q.success ? 'success' : 'error';
			return (
				<li className={`list__item list__item--${status}`} key={`question-${key}`}>
					<div className="item__icon">{icon}</div>
					<div className="item__content">
						<div className="item__question">
							{key + 1}.{' '}
							<em>
								"{q.clue.category.name}" for ${q.clue.value}
							</em>
							<br />
							{q.clue.question}
						</div>
						<div className="item__meta">
							<div className="meta__item">
								<Typography component="strong">Answer</Typography>{' '}
								<span>
									{q.clue.answer.replace(/\\/g, '')}
									{!q.success && q.guess ? <em> (you answered "{q.guess}")</em> : ''}
									{!q.success && !q.guess ? <em> (you didn't answer)</em> : ''}
								</span>
							</div>
						</div>
					</div>
				</li>
			);
		});

		return <ol className="questions__list">{listItems}</ol>;
	};

	return (
		<div className={`questions${classNames}`}>
			<div className="questions__mask">
				<Typography component="h2">Clues</Typography>
				{renderQuestions()}
			</div>
		</div>
	);
}

export default Questions;
